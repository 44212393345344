<div class="row d-flex justify-content-center">
    <div class="col-10 col-lg-6 d-flex justify-content-center">
        <form class="row d-flex justify-content-center" [formGroup]="resetForm" novalidate>
            <!-- HEADING -->
            <h3 class="col-12 mt-5 mb-4" translate>RESET.TITLE</h3>

            <!-- PASSWORD -->
            <div class="col-12">
                <label for="passwordInput" translate>FIELDS.NEW_PASSWORD</label>
                <div class="input-group mb-3">
                    <input type="password" class="form-control" id="passwordInput" formControlName="password" (change)="onPasswordInputChange()">
                </div>
            </div>

            <!-- PASSWORD CONFIRM -->
            <div class="col-12">
                <label for="passwordConfirmInput" translate>FIELDS.PASSWORD_CONFIRM</label>
                <div class="input-group mb-3">
                    <input type="password" class="form-control" id="passwordConfirmInput" formControlName="passwordConfirm">
                </div>
            </div>

            <!-- REGISTER BUTTON -->
            <div class="col-12 mb-3">
                <button type="submit" class="btn btn-primary" (click)="resetPassword()" translate>RESET.BUTTON</button>
            </div>

            <!-- ERROR MESSAGES -->
            <div class="col-12 mb-4">
                <!-- PASSWORD ERROR -->
                <div *ngIf="resetForm.controls['password'].invalid && (resetForm.controls['password'].dirty || resetForm.controls['password'].touched)" class="text-danger mb-2" translate>
                    ERRORS.SHORT_PASSWORD
                </div>

                <!-- PASSWORD CONFIRM ERROR -->
                <div *ngIf="resetForm.controls['passwordConfirm'].invalid && (resetForm.controls['passwordConfirm'].dirty || resetForm.controls['passwordConfirm'].touched)" class="text-danger mb-2" translate>
                    ERRORS.CONFIRM_PASSWORD_MATCH
                </div>

                <!-- SERVER RESPONSE ERROR -->
                <div *ngIf="errorMessage" class="text-danger mb-2" translate>
                    ERRORS.VAGUE
                </div>
            </div>
        </form>
    </div>
</div>