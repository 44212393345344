<div class="row d-flex justify-content-center">
    <div class="col-10 d-flex justify-content-center">
        <div class="row d-flex justify-content-center">
            <!-- HEADING -->
            <h3 class="col-12 mt-5 mb-4" translate>
                {{heading}}
            </h3>

            <!-- MESSAGE -->
            <div class="col-12">
                <p translate>{{message}}</p>
            </div>

            <!-- REDIRECT LINK -->
            <div class="d-inline-flex col-12">
                <div translate>CLICK_HERE_MESSAGE.PREFIX</div>
                <a [routerLink]="linkUrl" translate>CLICK_HERE_MESSAGE.HERE</a>
                <div translate [translateParams]="{linkName: translateService.instant(linkName)}">CLICK_HERE_MESSAGE.SUFFIX</div>
            </div>
        </div>
    </div>
</div>