<div class="row d-flex justify-content-center" style="height: 95vh">
    <div class="col-6 d-flex justify-content-center">
        <div class="row">
            <!-- TOP BUFFER -->
            <div class="col-12"></div>
            <div class="col-12"></div>
            <div class="col-12"></div>
            <div class="col-12"></div>
            <!-- CONTENT -->
            <div class="col-12 d-flex justify-content-center">
                <button type="button" class="btn btn-primary my-auto" (click)="onTestClick()">PRESS ME</button>
            </div>
            <div class="col-12 d-flex justify-content-center">
                <button type="button" class="btn btn-primary my-auto" (click)="onDrivingLogClick()">DRIVING LOG TEST</button>
            </div>
            <div class="col-12 d-flex justify-content-center">
                <button type="button" class="btn btn-primary my-auto" (click)="onDriveListClick()">TEST DRIVE API</button>
            </div>
            <div class="col-12 d-flex justify-content-center">
                <button type="button" class="btn btn-primary my-auto" (click)="tourSchedule()">DL: TOUR SCHEDULE</button>
            </div>
            <div class="col-12 d-flex justify-content-center">
                <button type="button" class="btn btn-primary my-auto" (click)="drivingLog()">DL: DRIVING LOG</button>
            </div>
            <!-- BOTTOM BUFFER -->
            <div class="col-12"></div>
            <div class="col-12"></div>
            <div class="col-12"></div>
            <div class="col-12"></div>
            <div class="col-12"></div>
        </div>
    </div>
</div>
