<div class="row d-flex justify-content-center">

    <!-- HEADING -->
    <h1 class="col-12 text-center mb-4" translate>STATS.TITLE</h1>


    <!-- YEAR SELECTOR -->
    <div class="col-12 d-flex justify-content-center mb-4">
        <select class="form-select" [ngModel]="selectedYearId" (ngModelChange)="onYearSelect($event)">
            <option *ngFor="let year of years" [value]="year.id">
                {{year.value}}
            </option>
        </select>
    </div>

    <!-- MAP CONTAINER -->
    <h3 class="col-12 mb-2" translate>STATS.PAX_NIGHTS_BY_LOCATION</h3>
    <div class="col-12 mb-2" translate [translateParams]="{number: totalNights}">STATS.TOTAL_PATTERN</div>
    <div class="col-12 mb-4">
        <div class="row d-flex justify-content-center">
            <!-- MAP -->
            <agm-map class="col-12" [latitude]="map_lat" [longitude]="map_lng" [zoom]="map_zoom"
                (mapReady)="mapReadyHandler($event)" [disableDefaultUI]="true">
                <!-- LABELS -->
                <agm-marker *ngFor="let m of markers" [latitude]="m.lat" [longitude]="m.lng" [label]="{
                        color: 'white',
                        text: m.description,
                        fontSize: '1em',
                        fontWeight: '800'
                    }" [iconUrl]="{
                        path: getSvgPath(),
                        anchor: anchorConst,
                        fillColor: 'purple',
                        fillOpacity: 0,
                        labelOrigin: labelOriginConst,
                        rotation: 0,
                        scale: 1,
                        strokeColor: 'black',
                        strokeOpacity: 0,
                        strokeWeight: 1
                    }">
                </agm-marker>
                <!-- CIRCLES -->
                <!-- <agm-circle
                    *ngFor="let m of markers"
                    [latitude]="m.lat"
                    [longitude]="m.lng"
                    [radius]="m.radius"
                    [fillColor]="'purple'"
                    [fillOpacity]="0.5"
                >
                </agm-circle> -->
                <!-- <agm-circle
                    [latitude]="43"
                    [longitude]="19"
                    [radius]="60000"
                    [clickable]="false"
                >
                </agm-circle> -->
                <agm-polygon *ngFor="let m of markers" [clickable]="false" [fillColor]="'purple'" [fillOpacity]="0.5"
                    [strokeOpacity]="0" [paths]="m.path">
                </agm-polygon>
                <!-- <agm-polygon 
                    [clickable]="false"
                    [fillColor]="'purple'"
                    [fillOpacity]="0.5"
                    [paths]="[
                        {lat: 42, lng: 19},
                        {lat: 42, lng: 21},
                        {lat: 43, lng: 21},
                        {lat: 43, lng: 19}
                    ]"
                >
                </agm-polygon> -->
            </agm-map>
        </div>
    </div>

</div>