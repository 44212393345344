<div class="row d-flex justify-content-center">
  <div class="col-12 d-flex justify-content-center">
    <h1 translate>LOCATIONS.TITLE</h1>
  </div>

  <!-- MAP -->
  <div class="col-12 col-lg-8 col-xxl-10">
    <agm-map
      class="row"
      [latitude]="map_lat"
      [longitude]="map_lng"
      [zoom]="map_zoom"
      (mapReady)="mapReadyHandler($event)"
    >
      <agm-marker 
        *ngFor="let m of markers; let i = index"
        [latitude]="m.lat"
        [longitude]="m.lng"
        [label]="{
          color: 'purple',
          text: m.hasName?translateService.instant('LOC.' + m.label):' ',
          fontSize: '1.8em',
          fontWeight: '500'
        }"
        (markerRightClick)="removeMarker(i)"
        [markerDraggable]="true"
        (dragEnd)="onMarkerDragEnd(i, $event)"
        [iconUrl]="iconObj"
        >
      </agm-marker>
    </agm-map>
  </div>

  <!-- LIST OF PLACES -->
  <div class="col-10 col-lg-4 col-xxl-2">
    <div class="row d-flex justify-content-center p-3">

      <!-- INPUT FOR NEW PLACE NAME -->
      <div class="col-10 form-group mb-2">
        <input 
          type="text" 
          class="form-control" 
          id="inputPlaceName" 
          #inputPlaceNameTag
          [placeholder]="translateService.instant('LOCATIONS.INPUT_NEW_LOCATION')" 
          (keyup.enter)="onEnterPress()"
          [(ngModel)]="newPlaceName"
        >
      </div>

      <!-- LIST -->
      <div *ngFor="let m of markers.slice().reverse(); let i = index" class="col-12">
        <div class="row mb-1 p-2 bg-light">
          <div class="col pl-4">{{m.hasName ? translateService.instant('LOC.' + m.label) : '...'}}</div>
        </div>
      </div>

    </div>
  </div>

</div>