<div class="container container-wide">
    <div class="row mb-3">
        <!-- LOGO -->
        <div class="col-6 col-sm-3 col-md-3 col-lg-2 mb-1 mr-auto">
            <a routerLink="/" class="d-inline-flex align-items-center mb-1">
                <img src="../../assets/ace/EYE-icon-subtle.png" class="eye-image-logo">
                <span class="ms-1" translate>TITLE</span>
            </a>
        </div>

        <!-- USER NAME -->
        <div *ngIf="user != null" class="col-6 col-md-4 col-lg-5 col-xl-6 text-end mb-1">
            {{user.name + ' ' + user.lastName}}
        </div>

        <!-- LOG OUT -->
        <button *ngIf="user != null" type="button" class="btn btn-danger col-3 col-md-2 col-lg-1 p-0 mb-1" (click)="logout()" translate>
            BUTTONS.LOGOUT
        </button>

        <!-- LANGUAGE -->
        <div class="col-7 col-sm-10 col-md-2 col-lg-3 col-xl-2 mb-1">
            <select class="form-select" [ngModel]="selectedLanguage" (ngModelChange)="onLanguageChange($event)">
                <option *ngFor="let lang of languages" [value]="lang" [translate]="'LANG.' + lang"></option>
            </select>
        </div>

        <!-- NAV MENU BUTTON -->
        <div *ngIf="user != null && isHigher" class="col-2 col-md-1 mb-1">
            <button 
                class="btn btn-outline-secondary nav-button" 
                type="button" 
                (click)="isCollapsed = !isCollapsed"
            >
                <img src="../../assets/gen-icons/hamburger.svg" class="nav-icon">
            </button>
        </div>

        <!-- NAV MENU - COLLAPSABLE -->
        <div *ngIf="isCollapsed == false" class="col-12">
            <div class="row justify-content-center border border-secondary rounded m-2 p-1">
                <a *ngIf="user != null && isHigher" routerLink="/ok/date-map" class="col-12 col-lg bg-light rounded m-1" translate>HOME</a>
                <a *ngIf="user != null && isHigher" routerLink="/ok/users" class="col-12 col-lg bg-light rounded m-1" translate>USERS.ALL</a>
                <a *ngIf="user != null && isAdmin" routerLink="/ok/new-locations" class="col-12 col-lg bg-light rounded m-1" translate>LOCATIONS.TITLE</a>
                <a *ngIf="user != null && isAdmin" routerLink="/ok/program-editor" class="col-12 col-lg bg-light rounded m-1" translate>SUBTITLES.PROGRAM_EDITOR</a>
                <a *ngIf="user != null && isAdmin" routerLink="/ok/stats" class="col-12 col-lg bg-light rounded m-1" translate>STATS.TITLE</a>
            </div>
        </div>
    </div>
    <!-- CHILD COMPONENT -->
    <router-outlet *ngIf="user != null"></router-outlet>
    <!-- LOADING COMPONENT -->
    <!-- TODO: Loading component improvement/replacement?
        Either make the loading element more presentable, or fix the original issue another way.
        The original issue was that the UserLayoutComponent and the nested Component both had http server requests in the ngOnInit method.
        Resulting in simultaneous requests, which is problematic with the newly added refresh tokens.
        The second request would try and use the now invalid refresh token, prompting session closure every time page was loaded while access token is expired.
    -->
    <div *ngIf="user == null">LOADING...</div>
</div>