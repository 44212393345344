<div class="row">
    <h1>Temporary heading</h1>
    <a routerLink="/ok/date-map">Date map</a>
    <div style="height: 200px"></div>
    <a routerLink="/login">Login</a>
    <a routerLink="/ok/new-locations">New locations</a>
    <a routerLink="/ok/program-editor">Program Editor</a>
    <a routerLink="/ok/users">Users</a>
    <a routerLink="/ok/stats">Stats</a>
    <a routerLink="/ok/test">Test</a>
</div>