<div class="row d-flex justify-content-center">

    <!-- UNKNOWN TOUR OPTIONS -->
    <div class="col-12 mb-2">
        <!-- UNKNOWS SHOWN -->
        <div class="form-check col-12">
            <input class="form-check-input" type="checkbox" [(ngModel)]="showUnknownTours"
                (ngModelChange)="onShowUnknownsChange()">
            <label class="form-check-label" for="flexCheckDefault" translate>MAP_OPTIONS.SHOW_UNKNOWNS</label>
        </div>
    </div>

    <!-- DATE SELECTOR -->
    <div class="col-12 mb-4">
        <div class="row justify-content-center">
            <button type="button" class="btn btn-secondary col-3" (click)="goToPreviousDate()">&lt;</button>
            <div class="col-6 mb-3 p-auto">
                <input type="date" class="form-control" id="dateInput" [(ngModel)]="selectedDateString"
                    (ngModelChange)="onDateChange($event)">
            </div>
            <button type="button" class="btn btn-secondary col-3" (click)="goToNextDate()">&gt;</button>
        </div>
    </div>

    <!-- FIELD SELECTOR -->
    <div class="col-12 mb-4">
        <div class="row justify-content-center">
            <!-- NAME -->
            <div class="form-check col-5 col-md-4 col-lg-3 col-xl mb-1">
                <input class="form-check-input" type="checkbox" [(ngModel)]="shortInfos.name"
                    (ngModelChange)="onShortInfoFieldsChange()">
                <label class="form-check-label" for="flexCheckDefault" translate>FIELD_PICKER.NAME</label>
            </div>
            <!-- START DATE -->
            <div class="form-check col-5 col-md-4 col-lg-3 col-xl mb-1">
                <input class="form-check-input" type="checkbox" [(ngModel)]="shortInfos.startDate"
                    (ngModelChange)="onShortInfoFieldsChange()">
                <label class="form-check-label" for="flexCheckDefault" translate>FIELD_PICKER.START_DATE</label>
            </div>
            <!-- TOUR GUIDE -->
            <div class="form-check col-5 col-md-4 col-lg-3 col-xl mb-1">
                <input class="form-check-input" type="checkbox" [(ngModel)]="shortInfos.tourGuides"
                    (ngModelChange)="onShortInfoFieldsChange()">
                <label class="form-check-label" for="flexCheckDefault" translate>FIELD_PICKER.TOUR_GUIDE</label>
            </div>
            <!-- GUESTS -->
            <div class="form-check col-5 col-md-4 col-lg-3 col-xl mb-1">
                <input class="form-check-input" type="checkbox" [(ngModel)]="shortInfos.guests"
                    (ngModelChange)="onShortInfoFieldsChange()">
                <label class="form-check-label" for="flexCheckDefault" translate>FIELD_PICKER.GUESTS</label>
            </div>
            <!-- HOTEL1 -->
            <div class="form-check col-5 col-md-4 col-lg-3 col-xl mb-1">
                <input class="form-check-input" type="checkbox" [(ngModel)]="shortInfos.hotel1"
                    (ngModelChange)="onShortInfoFieldsChange()">
                <label class="form-check-label" for="flexCheckDefault" translate>FIELD_PICKER.HOTEL1</label>
            </div>
            <!-- HOTEL2 -->
            <div class="form-check col-5 col-md-4 col-lg-3 col-xl mb-1">
                <input class="form-check-input" type="checkbox" [(ngModel)]="shortInfos.hotel2"
                    (ngModelChange)="onShortInfoFieldsChange()">
                <label class="form-check-label" for="flexCheckDefault" translate>FIELD_PICKER.HOTEL2</label>
            </div>
            <!-- ACTIVITIES -->
            <div class="form-check col-5 col-md-4 col-lg-3 col-xl mb-1">
                <input class="form-check-input" type="checkbox" [(ngModel)]="shortInfos.activities"
                    (ngModelChange)="onShortInfoFieldsChange()">
                <label class="form-check-label" for="flexCheckDefault" translate>FIELD_PICKER.ACTIVITY</label>
            </div>
            <!-- ROOMS -->
            <div class="form-check col-5 col-md-4 col-lg-3 col-xl mb-1">
                <input class="form-check-input" type="checkbox" [(ngModel)]="shortInfos.rooms"
                    (ngModelChange)="onShortInfoFieldsChange()">
                <label class="form-check-label" for="flexCheckDefault" translate>FIELD_PICKER.ROOMS</label>
            </div>
            <!-- VEHICLES -->
            <div class="form-check col-5 col-md-4 col-lg-3 col-xl mb-1">
                <input class="form-check-input" type="checkbox" [(ngModel)]="shortInfos.vehicles"
                    (ngModelChange)="onShortInfoFieldsChange()">
                <label class="form-check-label" for="flexCheckDefault" translate>FIELD_PICKER.VEHICLES</label>
            </div>
            <!-- CARRIERS -->
            <div class="form-check col-5 col-md-4 col-lg-3 col-xl mb-1">
                <input class="form-check-input" type="checkbox" [(ngModel)]="shortInfos.carriers"
                    (ngModelChange)="onShortInfoFieldsChange()">
                <label class="form-check-label" for="flexCheckDefault" translate>FIELD_PICKER.CARRIERS</label>
            </div>
        </div>
    </div>

    <!-- MAP CONTAINER -->
    <div class="col-12">
        <div class="row d-flex justify-content-center">
            <!-- MAP -->
            <agm-map class="col-12" [latitude]="map_lat" [longitude]="map_lng" [zoom]="map_zoom"
                (mapReady)="mapReadyHandler($event)" [disableDefaultUI]="true">
                <!-- TOURS -->
                <div *ngFor="let c of markerContainers">
                    <!-- POINT MARKERS -->
                    <agm-marker *ngFor="let m of c.markers; let i = index" [latitude]="m.lat" [longitude]="m.lng"
                        [label]="false?{
                            color: c.color,
                            text: m.label,
                            fontSize: '1.8em',
                            fontWeight: '500'
                        }:''" [iconUrl]="{
                            path: getSvgPath(m.iconMapKey),
                            anchor: anchorConst,
                            fillColor: c.color,
                            fillOpacity: 0.5,
                            labelOrigin: labelOriginConst,
                            rotation: 0,
                            scale: 1,
                            strokeColor: 'black',
                            strokeOpacity: c.opacity * 0.7,
                            strokeWeight: 1
                        }">
                    </agm-marker>
                    <!-- POLYLINE -->
                    <agm-polyline *ngIf="c.markers.length > 0" [strokeColor]="c.color" [strokeOpacity]="c.opacity">
                        <agm-polyline-point *ngFor="let point of c.markers; let i = index" [latitude]="point.lat"
                            [longitude]="point.lng">
                        </agm-polyline-point>
                    </agm-polyline>
                </div>
            </agm-map>
        </div>
    </div>

</div>